// @flow strict
import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import * as Immutable from 'immutable';
import { Col, ControlLabel, Form, Row } from 'react-bootstrap';

// $FlowFixMe: imports from core need to be fixed in flow
import connect from 'stores/connect';

import { SearchParameterActions, SearchParameterStore } from 'enterprise/parameters/stores/SearchParameterStore';
import { getParameterBindingsAsMap } from 'views/logic/search/SearchExecutionState';
import ParameterType from 'views/logic/parameters/Parameter';
import type { ParameterBindings } from 'views/logic/search/SearchExecutionState';
import Parameter from './Parameter';

type Props = {
  onChange: (string, any) => void,
  onExecute: () => void,
  parameters: Immutable.Set<ParameterType>,
  parameterBindings: ParameterBindings,
};

const handleDelete = (name: string) => SearchParameterActions.remove(name);

const handleEdit = (name: string, parameter: ParameterType) => SearchParameterActions.update(name, parameter);

const Parameters = ({ onChange, onExecute, parameters, parameterBindings }: Props) => {
  if (parameters.size === 0) {
    return null;
  }

  const bindings = getParameterBindingsAsMap(parameterBindings);

  const handleSubmit = (e) => {
    e.preventDefault();
    onExecute();
  };

  const parameterFields = parameters
    .sortBy((p1: ParameterType, p2: ParameterType) => p1.title.localeCompare(p2.title))
    .map(param => (
      <Parameter key={param.name}
                 parameter={param}
                 binding={bindings.get(param.name)}
                 onChange={onChange}
                 onDelete={handleDelete}
                 onEdit={handleEdit} />
    ))
    .valueSeq()
    .toJS();

  return (
    <Row className="content">
      <Col md={12}>
        <Form inline onSubmit={handleSubmit}>
          <ControlLabel>Parameters</ControlLabel>
          {parameterFields}
          <input type="submit" style={{ display: 'none' }} />
        </Form>
      </Col>
    </Row>
  );
};

Parameters.propTypes = {
  parameterBindings: ImmutablePropTypes.map.isRequired,
  parameters: ImmutablePropTypes.map.isRequired,
  onChange: PropTypes.func.isRequired,
  onExecute: PropTypes.func.isRequired,
};

export default connect(Parameters, { parameters: SearchParameterStore });
