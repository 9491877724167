// @flow strict
import React from 'react';
import PropTypes from 'prop-types';
import * as Immutable from 'immutable';
import { Button, Col, ControlLabel, Form, FormControl, FormGroup, HelpBlock, Row } from 'react-bootstrap';

import FormsUtils from 'util/FormsUtils';

import { SearchExecutionStateActions } from 'views/stores/SearchExecutionStateStore';

import CustomPropTypes from 'views/components/CustomPropTypes';
import fixup from 'views/pages/StyleFixups.css';
import type { ParameterBindings } from 'views/logic/search/SearchExecutionState';
import SearchExecutionState from 'views/logic/search/SearchExecutionState';
import Parameter from 'views/logic/parameters/Parameter';

type Props = {
  viewTitle: string,
  parameters: ParameterBindings,
  onSubmit: (SearchExecutionState) => void,
};

export default class ParameterInputForm extends React.Component<Props, any> {
  static propTypes = {
    viewTitle: PropTypes.string,
    parameters: CustomPropTypes.instanceOf(Immutable.Map).isRequired,
    onSubmit: PropTypes.func,
  };

  static defaultProps = {
    onSubmit: () => {},
    viewTitle: 'Unnamed',
  };

  constructor(props: Props) {
    super(props);

    const values = {};

    props.parameters.valueSeq().forEach((param: Parameter) => {
      if (param.binding.value || param.defaultValue) {
        values[param.name] = param.binding.value || param.defaultValue;
      }
    });

    this.state = values;
  }

  // eslint-disable-next-line no-undef
  handleValueChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ [e.target.name]: FormsUtils.getValueFromInput(e.target) });
  };

  // eslint-disable-next-line no-undef
  handleFormSubmit = (e: SyntheticEvent<*>) => {
    e.preventDefault();
    SearchExecutionStateActions.setParameterValues(Immutable.fromJS(this.state)).then(newExecutionState => this.props.onSubmit(newExecutionState));
  };

  render() {
    const { parameters } = this.props;
    const parameterInputs = parameters.valueSeq().map((parameter: Parameter) => {
      const { name, title, description } = parameter;

      return (
        <FormGroup key={`parameter-${name}`} controlId={`parameter-${name}`}>
          <Col componentClass={ControlLabel} sm={3}>
            {title}
          </Col>
          <Col sm={9}>
            <FormControl type="text"
                         placeholder="Please enter a parameter value..."
                         required
                         name={name}
                         value={this.state[name] || ''}
                         onChange={this.handleValueChange} />
            <HelpBlock>{description}</HelpBlock>
          </Col>
        </FormGroup>
      );
    });

    return (
      <Row className="content">
        <Form horizontal onSubmit={this.handleFormSubmit}>
          <Col md={8} mdOffset={3} className={fixup.bootstrapHeading}>
            <h1>View: <em>{this.props.viewTitle}</em></h1>
            <p>This view uses parameters which need to be entered before it can be executed. Please enter the parameter values into the form.</p>
          </Col>
          <Col md={8} mdOffset={1}>
            {parameterInputs}
          </Col>
          <Col md={8} mdOffset={3}>
            <Button type="submit" bsStyle="success">Continue</Button>
          </Col>
        </Form>
      </Row>
    );
  }
}
