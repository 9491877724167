import PropTypes from 'prop-types';
import React from 'react';
import lodash from 'lodash';
import { Button, Col, DropdownButton, MenuItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { EntityListItem } from 'components/common';
import Routes from 'routing/Routes';

import ReportsActions from '../ReportsActions';
import ReportsStore from '../ReportsStore';
import { isValidLicense } from '../logic/license';

class Report extends React.Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
    licenseStatus: PropTypes.object.isRequired,
  };

  _reportHasRecipients = (report) => {
    const { scheduling } = report;
    return scheduling && (
      (Array.isArray(scheduling.user_receivers) && scheduling.user_receivers.length > 0)
      || (Array.isArray(scheduling.email_receivers) && scheduling.email_receivers.length > 0)
    );
  };

  _sendReportEmail = () => {
    ReportsActions.sendEmail(this.props.report);
  };

  _formatScheduling = (report) => {
    if (!report.scheduling || lodash.isEmpty(report.scheduling) || !report.scheduling.active) {
      return <p>This report is not scheduled to be sent automatically.</p>;
    }

    return <p>This report is scheduled to be sent automatically on a {report.scheduling.frequency} basis.</p>;
  };

  _deleteReport = () => {
    const { report } = this.props;
    if (window.confirm(`Do you really want to delete report "${report.title}"? This action cannot be undone.`)) {
      ReportsActions.delete(report);
    }
  };

  render() {
    const { licenseStatus, report } = this.props;
    const isSendReportEnabled = this._reportHasRecipients(report) && isValidLicense(licenseStatus);
    let sendReportText = 'Send report now';
    if (!isValidLicense(licenseStatus)) {
      sendReportText = 'Sending report disabled, no valid license found';
    } else if (!this._reportHasRecipients(report)) {
      sendReportText = 'Sending report disabled, add recipients first';
    }

    // TODO: Check permissions here
    const actions = [
      <LinkContainer key="contents-button" to={Routes.pluginRoute('REPORTS_REPORTID_CONTENTS')(report.id)}>
        <Button bsStyle="info">Contents</Button>
      </LinkContainer>,
      <LinkContainer key="configuration-button" to={Routes.pluginRoute('REPORTS_REPORTID_CONFIGURATION')(report.id)}>
        <Button bsStyle="info">Configuration</Button>
      </LinkContainer>,
      <DropdownButton key="more-actions-button"
                      title="More actions"
                      pullRight
                      id={`more-actions-dropdown-${report.id}`}>
        <LinkContainer to={Routes.pluginRoute('REPORTS_REPORTID_HISTORY')(report.id)}>
          <MenuItem>Report history</MenuItem>
        </LinkContainer>
        <MenuItem href={ReportsStore.getDownloadURL(report)} target="_blank">Download report now</MenuItem>
        <MenuItem disabled={!isSendReportEnabled} onSelect={this._sendReportEmail}>{sendReportText}</MenuItem>
        <MenuItem divider />
        <MenuItem onSelect={this._deleteReport}>Delete</MenuItem>
      </DropdownButton>,
    ];

    const content = (
      <Col md={12}>
        {this._formatScheduling(report)}
      </Col>
    );

    return (
      <EntityListItem key={`entry-list-${report.id}`}
                      title={report.title}
                      description={report.description}
                      actions={actions}
                      contentRow={content} />
    );
  }
}

export default Report;
