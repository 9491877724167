import PropTypes from 'prop-types';
import React from 'react';
import lodash from 'lodash';
import moment from 'moment';
import { Col, FormControl, FormGroup, Row } from 'react-bootstrap';

import { Input } from 'components/bootstrap';
import { Select } from 'components/common';
import DateTime from 'logic/datetimes/DateTime';

import TimeSelect from './TimeSelect';
import DayOfWeekSelect from './DayOfWeekSelect';
import DayOfMonthSelect from './DayOfMonthSelect';

const FREQUENCY_VALUES = ['daily', 'weekly', 'monthly'];
export const FREQUENCY_OPTIONS = {
  daily: {
    value: 'daily',
    label: 'Daily',
  },
  weekly: {
    value: 'weekly',
    label: 'Weekly',
  },
  monthly: {
    value: 'monthly',
    label: 'Monthly',
  },
};

class RecurringEventControl extends React.Component {
  static propTypes = {
    disabled: PropTypes.bool,
    frequency: PropTypes.string,
    frequency_configuration: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    disabled: false,
    frequency: undefined,
  };

  _propagateChanges = (frequency, configuration) => {
    const update = {
      frequency: frequency,
      frequency_configuration: configuration,
    };

    this.props.onChange(update);
  };

  _handleFrequencyChange = (value) => {
    this._propagateChanges(value, {});
  };

  _handleFrequencyConfigurationChange = (name) => {
    return (value) => {
      const updatedConfiguration = lodash.cloneDeep(this.props.frequency_configuration);
      if (name === 'time') {
        // Convert local time to UTC, as we use that to store it in the server.
        updatedConfiguration[name] = this._getUTCTime(value);
      } else {
        updatedConfiguration[name] = value;
      }

      this._propagateChanges(this.props.frequency, updatedConfiguration);
    };
  };

  _getUTCTime = (time) => {
    if (!time) {
      return time;
    }

    const [hours, minutes] = time.split(':');
    const dateTime = DateTime.now().hours(hours).minutes(minutes);
    return dateTime.utc().format('HH:mm');
  };

  _getLocalTime = (time) => {
    if (!time) {
      return time;
    }
    const [hours, minutes] = time.split(':');
    const dateTime = moment.utc().hours(hours).minutes(minutes);
    return new DateTime(dateTime).toString('HH:mm');
  };

  _formatTime = (time) => {
    return `${this._getLocalTime(time)} local time (${time} UTC)`;
  };

  _renderFrequencyConfiguration = (frequency, disabled, frequencyConfiguration) => {
    let summary = 'No options selected.';
    let helpText;
    let controls;
    let timeControl;

    if (frequencyConfiguration) {
      // Time is set in UTC, convert it to the equivalent local time for the current user
      timeControl = (
        <TimeSelect id="time"
                    value={this._getLocalTime(frequencyConfiguration.time)}
                    disabled={disabled}
                    required
                    onChange={this._handleFrequencyConfigurationChange('time')} />
      );
    }

    switch (frequency) {
      case FREQUENCY_OPTIONS.daily.value:
        if (frequencyConfiguration.time) {
          summary = `Report will be sent every day at ${this._formatTime(frequencyConfiguration.time)}.`;
        }
        helpText = 'Select the time of the day when the report should be sent out.';
        controls = timeControl;
        break;
      case FREQUENCY_OPTIONS.weekly.value:
        if (frequencyConfiguration.day_of_week && frequencyConfiguration.time) {
          summary = `Report will be sent every ${lodash.upperFirst(frequencyConfiguration.day_of_week)} at ${this._formatTime(frequencyConfiguration.time)}.`;
        }
        helpText = 'Select the day of the week and time of the day when the report should be sent out.';

        controls = (
          <Row>
            <Col sm={6}>
              <DayOfWeekSelect id="day_of_week"
                               value={frequencyConfiguration.day_of_week}
                               disabled={disabled}
                               required
                               onChange={this._handleFrequencyConfigurationChange('day_of_week')} />
            </Col>
            <Col sm={6}>
              {timeControl}
            </Col>
          </Row>
        );
        break;
      case FREQUENCY_OPTIONS.monthly.value:
        if (frequencyConfiguration.day_of_month && frequencyConfiguration.time) {
          const ordinalDay = moment.localeData().ordinal(lodash.upperFirst(frequencyConfiguration.day_of_month));
          summary = `Report will be sent the ${ordinalDay} day of every month at ${this._formatTime(frequencyConfiguration.time)}.`;
        }
        helpText = 'Select the day of the month and time of the day when the report should be sent out.';
        controls = (
          <Row>
            <Col sm={6}>
              <DayOfMonthSelect id="day_of_month"
                                value={frequencyConfiguration.day_of_month}
                                disabled={disabled}
                                required
                                onChange={this._handleFrequencyConfigurationChange('day_of_month')} />
            </Col>
            <Col sm={6}>
              {timeControl}
            </Col>
          </Row>
        );
        break;
      default:
        return <div />;
    }

    return (
      <Input id="frequency-options-preview" label={`${FREQUENCY_OPTIONS[frequency].label} frequency options`} help={helpText}>
        <div>
          <FormControl.Static className="text-info"><i className="fa fa-info-circle" /> {summary}</FormControl.Static>
          <FormGroup>
            {controls}
          </FormGroup>
        </div>
      </Input>
    );
  };

  render() {
    const { disabled, frequency } = this.props;
    const frequencyConfiguration = this.props.frequency_configuration;
    const frequencyOptions = FREQUENCY_VALUES.map(value => FREQUENCY_OPTIONS[value]);

    return (
      <div>
        <Input id="frequency-select" label="Frequency" help="Select how often this report should be sent out.">
          <Select id="frequency"
                  options={frequencyOptions}
                  value={frequency}
                  placeholder="Select a frequency"
                  disabled={disabled}
                  required
                  onChange={this._handleFrequencyChange} />
        </Input>
        {this._renderFrequencyConfiguration(frequency, disabled, frequencyConfiguration)}
      </div>
    );
  }
}

export default RecurringEventControl;
