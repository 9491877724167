// @flow strict
import React from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { capitalize } from 'lodash';

import CustomPropTypes from 'views/components/CustomPropTypes';
import Parameter from 'views/logic/parameters/Parameter';
import ParameterBinding from 'views/logic/parameters/ParameterBinding';
import type { ParameterMap } from 'views/logic/parameters/Parameter';

import ParameterDeclarationForm from './ParameterDeclarationForm';

type Props = {
  undeclaredParameters: Immutable.Set<string>,
  onParameterSave: (ParameterMap) => void,
}

type DeclarationForm = {
  open: () => void,
};

class ParameterBar extends React.Component<Props> {
  static propTypes = {
    undeclaredParameters: CustomPropTypes.instanceOf(Immutable.Set).isRequired,
    onParameterSave: PropTypes.func.isRequired,
  };

  declarationForm: ?DeclarationForm;

  handleDeclareParameters = () => {
    if (this.declarationForm) {
      this.declarationForm.open();
    }
  };

  render() {
    const undeclaredParameterNames = this.props.undeclaredParameters;

    if (undeclaredParameterNames.size === 0) {
      return null;
    }

    const undeclaredParameters = Immutable.Map(undeclaredParameterNames.map((name: string) => ([
      name,
      Parameter.create(name, capitalize(name), '', 'string', undefined, false, ParameterBinding.empty()),
    ])));

    return (
      <Row className="content" style={{ marginRight: 0, marginLeft: 0 }}>
        <Col md={12}>
          <Alert bsStyle="danger">
            <h4><strong>Undeclared query parameters:</strong> {undeclaredParameterNames.join(', ')}</h4>
            <br />
            <p>All parameters used in the search query need to be declared before the query can be used:</p>
            <br />
            <Button bsStyle="primary" bsSize="small" onClick={this.handleDeclareParameters}>Declare parameters</Button>
          </Alert>
          <ParameterDeclarationForm ref={(c: ?DeclarationForm) => { this.declarationForm = c; }}
                                    parameters={undeclaredParameters}
                                    onSave={this.props.onParameterSave} />
        </Col>
      </Row>
    );
  }
}

export default ParameterBar;
