import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Button } from 'react-bootstrap';
import { Link } from 'react-router';

import Routes from 'routing/Routes';
import { isPermitted } from 'util/PermissionsMixin';

import styles from './LicenseNotification.css';

class LicenseExpirationWarningNotification extends React.Component {
  static propTypes = {
    expiresIn: PropTypes.string.isRequired,
    onReminderClick: PropTypes.func.isRequired,
    enableReminderButton: PropTypes.bool,
    currentUser: PropTypes.object.isRequired,
  };

  static defaultProps = {
    enableReminderButton: true,
  };

  render() {
    // Only display expiration warning for admin users
    if (!isPermitted(this.props.currentUser.permissions, 'licenses:create')) {
      return null;
    }

    return (
      <div className={`container-fluid ${styles.licenseNotification}`}>
        <Alert bsStyle="warning">
          <p>
            <strong>Graylog Enterprise License Expiration Warning:</strong>
            &nbsp;
            Your Graylog Enterprise license will expire in <strong>{this.props.expiresIn}</strong>.
            Go to the <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for
            more information or contact your Graylog account manager.
            {this.props.enableReminderButton
            && (
            <Button bsSize="xsmall" className="pull-right" onClick={this.props.onReminderClick}>
              <i className="fa fa-times" /> Remind me later
            </Button>
            )
            }
          </p>
        </Alert>
      </div>
    );
  }
}

export default LicenseExpirationWarningNotification;
