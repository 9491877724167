import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router';

import Routes from 'routing/Routes';
import { isPermitted } from 'util/PermissionsMixin';

import styles from './LicenseNotification.css';

class LicenseTrialNotification extends React.Component {
  static propTypes = {
    expiresIn: PropTypes.string.isRequired,
    currentUser: PropTypes.object.isRequired,
  };

  render() {
    return (
      <div className={`container-fluid ${styles.licenseNotification}`}>
        <Alert bsStyle="info">
          <h4 style={{ marginBottom: 5 }}><strong>Graylog Enterprise Trial License</strong></h4>
          <p>
            Your trial license will expire in <strong>{this.props.expiresIn}</strong>.{' '}
            {isPermitted(this.props.currentUser.permissions, 'licenses:create') ? (
              <span>
                Go to the <Link to={Routes.pluginRoute('SYSTEM_LICENSES')}>Licenses page</Link> for
                more information or contact your Graylog account manager.
              </span>
            ) : (
              <span>Contact your Graylog account manager for more information.</span>
            )}
          </p>
        </Alert>
      </div>
    );
  }
}

export default LicenseTrialNotification;
