import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { Timestamp, Spinner, IfPermitted, PaginatedList } from 'components/common';

import moment from 'moment';
import {} from 'moment-duration-format';
import {} from 'twix';
import NumberUtils from 'util/NumberUtils';
import naturalSort from 'javascript-natural-sort';

import ArchiveActions from 'archive/ArchiveActions';

class ArchiveCatalogTableEntry extends React.Component {
  static propTypes = {
    archive: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    diskState: PropTypes.object.isRequired,
  };

  componentWillMount() {
    const streamHistograms = this._calcStreamHistograms(this.props.archive);
    this.setState({
      streamHistograms: streamHistograms,
      currentStreamHistogramPage: this._getHistogramPageContent(this.state.streamHistogramPage, this.state.streamHistogramPageSize),
    });
  }

  componentWillReceiveProps(newProps) {
    const streamHistograms = this._calcStreamHistograms(newProps.archive);
    this.setState({
      streamHistograms: streamHistograms,
      currentStreamHistogramPage: this._getHistogramPageContent(this.state.streamHistogramPage, this.state.streamHistogramPageSize),
    });
  }

  DEFAULT_PAGE_SIZE = 5;

  toggleDetails = (e) => {
    e.preventDefault();

    const expanding = !this.state.showDetails;
    if (expanding) {
      // check the availability of the archive on disk
      ArchiveActions.availability(this.props.archive.id);
    }
    this.setState({ showDetails: expanding });
  };

  bodyStyle = () => {
    return this.state.showDetails ? 'archive-catalog-entry details-visible' : 'archive-catalog-entry';
  };

  _onRestoreIndex = (archive) => {
    return () => {
      ArchiveActions.restoreArchive(archive.backend_id, archive.archive_id);
    };
  };

  _onDeleteArchive = (archive) => {
    return () => {
      // noinspection Eslint
      if (window.confirm(`Are you sure you want to delete archive "${archive.archive_id}" for index "${archive.index_name}"?`)) {
        ArchiveActions.deleteArchive(archive.backend_id, archive.archive_id);
      }
    };
  };

  _getStreamName = (streamId) => {
    if (!this.props.archive.id_mappings || !this.props.archive.id_mappings.streams) {
      return streamId;
    }

    return this.props.archive.id_mappings.streams[streamId] || streamId;
  };

  _onPageChange = (newPage, pageSize) => {
    if (this.state.streamHistograms.length > 0) {
      const newPageContent = this._getHistogramPageContent(newPage, pageSize);
      this.setState({
        currentStreamHistogramPage: newPageContent,
        streamHistogramPage: newPage,
        streamHistogramPageSize: pageSize,
      });
    }
  };

  _getHistogramPageContent = (page, size) => {
    return this.state.streamHistograms.slice((page - 1) * size, page * size);
  };

  _calcStreamHistograms = (archive) => {
    const streams = {};

    Object.keys(archive.stream_histogram).forEach((date) => {
      Object.keys(archive.stream_histogram[date]).forEach((streamId) => {
        if (!streams[streamId]) {
          streams[streamId] = 0;
        }
        streams[streamId] += archive.stream_histogram[date][streamId];
      });
    });
    return Object.keys(streams).map(streamId => ({ streamId: streamId, name: this._getStreamName(streamId), count: streams[streamId] }))
      .sort((a, b) => naturalSort(a.name, b.name));
  };

  _renderStreamsDetails = () => {
    if (this.state.streamHistograms.length < 1) {
      return null;
    }

    const streamRows = this.state.currentStreamHistogramPage
      .map((histogramEntry) => {
        return (
          <tr key={histogramEntry.streamId}>
            <td>{histogramEntry.name}</td>
            <td>{NumberUtils.formatNumber(histogramEntry.count)}</td>
          </tr>
        );
      });

    return (
      <div className="deflist">
        <div className="table-responsive">
          <PaginatedList onChange={this._onPageChange}
                         totalItems={this.state.streamHistograms.length}
                         pageSize={this.DEFAULT_PAGE_SIZE}
                         pageSizes={[5, 10, 20, 50]}>
            <p>Number of archived messages per stream. <strong>Note:</strong> Messages can be in multiple streams.</p>
            <Table striped bordered condensed>
              <thead>
                <tr>
                  <th>Stream</th>
                  <th>Message count</th>
                </tr>
              </thead>
              <tbody>
                {streamRows}
              </tbody>
            </Table>
          </PaginatedList>
        </div>
      </div>
    );
  };

  _renderDetails = (archive, context) => {
    const segmentSize = archive.segments.reduce((sum, segment) => sum + segment.size, 0);
    const segmentRawSize = archive.segments.reduce((sum, segment) => sum + segment.raw_size, 0);
    const compressionType = archive.segments.map(segment => segment.compression_type)[0];

    let restoreButton;
    if (context.restored) {
      restoreButton = (<Button bsStyle="success" bsSize="xs" disabled>Restored as {context.restored_index_name}</Button>);
    } else {
      restoreButton = (
        <IfPermitted permissions="archive:restore">
          <Button bsStyle="success" bsSize="xs" onClick={this._onRestoreIndex(archive)}>Restore index</Button>
        </IfPermitted>
      );
    }
    let rawSizeInfo;
    if (segmentRawSize > 0) {
      rawSizeInfo = ` / ${NumberUtils.formatBytes(segmentRawSize)} uncompressed`;
    }

    let archiveFileCheck = null;
    if (this.props.diskState.loading) {
      archiveFileCheck = <Spinner text="Checking archive availability" />;
    } else if (this.props.diskState.available) {
      archiveFileCheck = (<span><i className="fa fa-check text-success" />&nbsp;Archive available</span>);
    } else {
      archiveFileCheck = (<span><i className="fa fa-remove text-danger" />&nbsp;Archive not available</span>);
    }

    return (
      <span>
        <Row>
          <Col md={6}>
            <dl className="deflist">
              <dt>Index name:</dt>
              <dd>{archive.index_name}</dd>
              <dt>Created:</dt>
              <dd><Timestamp dateTime={archive.created_at} /> (took {moment.duration(archive.creation_duration, 'milliseconds').format('h [h] m [min] s [sec] S [ms]')})</dd>
              <dt>Message count:</dt>
              <dd>{NumberUtils.formatNumber(archive.document_count)}</dd>
              <dt>Earliest message:</dt>
              <dd><Timestamp dateTime={archive.timestamp_min} /></dd>
              <dt>Latest message:</dt>
              <dd><Timestamp dateTime={archive.timestamp_max} /></dd>
              <dt>Segment count:</dt>
              <dd>{archive.segments.length}</dd>
              <dt>Segments size:</dt>
              <dd>{NumberUtils.formatBytes(segmentSize)} (compressed with {compressionType}{rawSizeInfo})</dd>
              <dt>Segment directory:</dt>
              <dd>{context.path}</dd>
              <dt>Archive availability</dt>
              <dd>{archiveFileCheck}</dd>
              {
                context.restored
                && (
                <span>
                  <dt>Restored index:</dt>
                  <dd>{context.restored_index_name}</dd>
                </span>
                )
              }
            </dl>
          </Col>
          <Col md={6}>
            {this._renderStreamsDetails()}
          </Col>
        </Row>
        <IfPermitted permissions={['archive:create', 'archive:delete']} anyPermission>
          <hr style={{ marginBottom: '5', marginTop: '10' }} />
          {restoreButton}
          <IfPermitted permissions="archive:delete">
            &nbsp;
            <Button bsStyle="danger" bsSize="xs" onClick={this._onDeleteArchive(archive)}>Delete archive</Button>{' '}
          </IfPermitted>
        </IfPermitted>
      </span>
    );
  };

  _renderStreamNames = () => {
    if (!(this.props.archive.stream_names)) {
      return <em>No streams</em>;
    }

    return this.props.archive.stream_names
      .sort((a, b) => naturalSort(a, b))
      .join(', ');
  };

  state = {
    showDetails: false,
    streamHistograms: [],
    currentStreamHistogramPage: [],
    streamHistogramPage: 1,
    streamHistogramPageSize: this.DEFAULT_PAGE_SIZE,
  };

  render() {
    const contentRange = moment(this.props.archive.timestamp_min).twix(this.props.archive.timestamp_max);

    return (
      <tbody className={this.bodyStyle()}>
        <tr onClick={this.toggleDetails} className="toggle-details">
          <td>
            <strong>{this.props.archive.index_name}</strong>
          </td>
          <td><Timestamp dateTime={this.props.archive.created_at} relative /></td>
          <td>{contentRange.format({ hourFormat: 'H' })}</td>
          <td>{NumberUtils.formatNumber(this.props.archive.document_count)} msgs ({contentRange.humanizeLength()})</td>
          <td className="stretch">
            {this._renderStreamNames()}
          </td>
          <td className="restored">
            {this.props.context.restored ? <i className="fa fa-check" /> : null}
          </td>
        </tr>
        {this.state.showDetails
          && (
          <tr className="archive-catalog-entries-details">
            <td colSpan="6">
              {this._renderDetails(this.props.archive, this.props.context)}
            </td>
          </tr>
          )
        }
      </tbody>
    );
  }
}

export default ArchiveCatalogTableEntry;
