import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';

import { DocumentTitle, PageHeader } from 'components/common';
import ArchiveConfigForm from 'archive/components/ArchiveConfigForm';
import ArchiveLicenseStatusContainer from 'archive/components/ArchiveLicenseStatusContainer';

import Routes from 'routing/Routes';

class ArchiveConfigurationPage extends React.Component {
  render() {
    return (
      <DocumentTitle title="Archives configuration">
        <div>
          <PageHeader title="Edit archives configuration">
            <span>
              The Graylog archive feature allows you to create archives from indices. The generated archives
              are simple flat files that can be moved to cheap storage and re-imported at any time.
            </span>
            <span>
              Archive your old indices automatically by setting your index retention strategy to archive
              on the{' '} <Link to={Routes.SYSTEM.INDICES.LIST}>indices</Link>{' '}
              page.
            </span>
            <span>
              <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES')}>
                <Button bsStyle="info">Overview</Button>
              </LinkContainer>
              &nbsp;
              <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES_CONFIGURATION')}>
                <Button bsStyle="info" className="active">Configuration</Button>
              </LinkContainer>
              &nbsp;
              <LinkContainer to={Routes.pluginRoute('SYSTEM_ARCHIVES_BACKENDS')}>
                <Button bsStyle="info">Manage Backends</Button>
              </LinkContainer>
            </span>
          </PageHeader>
          <ArchiveLicenseStatusContainer />
          <Row className="content">
            <Col lg={8}>
              <ArchiveConfigForm />
            </Col>
          </Row>
        </div>
      </DocumentTitle>
    );
  }
}

export default ArchiveConfigurationPage;
