import PropTypes from 'prop-types';
import React from 'react';
import lodash from 'lodash';
import { Alert, Button, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap';
import { PluginStore } from 'graylog-web-plugin/plugin';
import numeral from 'numeral';

import FormsUtils from 'util/FormsUtils';
import { naturalSortIgnoreCase } from 'util/SortUtils';
import { Input } from 'components/bootstrap';
import { Pluralize, ExpandableList, ExpandableListItem } from 'components/common/';

import ReportContentsToolbar from './ReportContentsToolbar';
import ReportingWidget from '../common/ReportingWidget';
import ReportingWidgetContainer from '../common/ReportingWidgetContainer';

import style from './ReportContentsSelection.css';
import commonStyles from '../commonStyles.css';

const MAX_DASHBOARDS_TO_EXPAND = 5;
const MAX_LOGO_SIZE = 1024 * 1024; // 1MB

class ReportContentsSelection extends React.Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
    reportLogo: PropTypes.string,
    formElementId: PropTypes.string.isRequired,
    dashboards: PropTypes.array.isRequired,
    onReportChange: PropTypes.func.isRequired,
    onReportLogoChange: PropTypes.func.isRequired,
    onSaveReport: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    action: PropTypes.oneOf(['create', 'edit']),
    isLoading: PropTypes.bool,
  };

  static defaultProps = {
    reportLogo: null,
    action: 'create',
    isLoading: false,
  };

  state = {
    logoError: undefined,
  };

  _propagateUpdate = (updatedReport) => {
    this.props.onReportChange(updatedReport);
  };

  _updateProp = (key, value) => {
    const updatedReport = lodash.cloneDeep(this.props.report);
    updatedReport[key] = value;
    this._propagateUpdate(updatedReport);
  };

  _updateReport = (event) => {
    this._updateProp(event.target.name, FormsUtils.getValueFromInput(event.target));
  };

  _updateReportLogo = (event) => {
    const { target } = event;
    if (target.files.length === 0) {
      return;
    }

    const file = target.files[0];
    if (file.size > MAX_LOGO_SIZE) {
      this.setState({ logoError: { message: `Image size is larger than ${numeral(MAX_LOGO_SIZE).format('0 b')}, please resize the image or pick a smaller one.` } });
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ logoError: undefined });
      this.props.onReportLogoChange(reader.result);
    };
    reader.readAsDataURL(file);
  };

  _removeReportLogo = () => {
    this.props.onReportLogoChange(null);
  };

  _updateWidgetSelection = (event) => {
    const updatedReport = lodash.cloneDeep(this.props.report);
    const dashboardId = event.target.name;
    const widgetId = event.target.value;
    const isWidgetChecked = FormsUtils.getValueFromInput(event.target);

    const dashboard = this._getDashboard(this.props.dashboards, dashboardId);
    const widgetsToUpdate = widgetId === 'dashboard' ? dashboard.widgets : dashboard.widgets.filter(w => w.id === widgetId);
    const updatedWidgets = widgetsToUpdate
      .map((originalWidget) => {
        const widget = lodash.cloneDeep(originalWidget);
        widget.dashboard_id = dashboardId;
        widget.dashboard_widget_id = widget.id;

        return widget;
      });

    const reportWidgets = updatedReport.widgets;
    if (isWidgetChecked) {
      updatedReport.widgets = lodash.unionBy(reportWidgets, updatedWidgets, 'dashboard_widget_id');
    } else {
      updatedReport.widgets = lodash.xorBy(reportWidgets, updatedWidgets, 'dashboard_widget_id');
    }

    this._propagateUpdate(updatedReport);
  };

  _saveReport = (event) => {
    event.preventDefault();
    this.props.onSaveReport();
  };

  _getDashboard = (dashboards, dashboardId) => {
    const dashboard = dashboards.find(d => d.id === dashboardId);
    if (!dashboard || dashboard.widgets.length === 0) {
      return undefined;
    }
    return dashboard;
  };

  _isWidgetInReport = (report, widgetId) => {
    return report.widgets.some(w => w.dashboard_widget_id === widgetId);
  };

  _numberDashboardWidgetsInReport = (report, dashboard) => {
    return dashboard.widgets.filter(dw => report.widgets.find(rw => rw.dashboard_widget_id === dw.id) !== undefined).length;
  };

  _formatWidgetInput = (report, dashboard, widget) => {
    const widgetType = PluginStore.exports('widgets').find(w => w.type.toUpperCase() === widget.type.toUpperCase());
    let formattedWidget;
    if (widgetType) {
      formattedWidget = (
        <ReportingWidgetContainer>
          <ReportingWidget dashboardId={dashboard.id}
                           widget={widget}
                           showHeading={false}
                           showCaption={false}
                           showHandle={false}
                           interactive={false}
                           height={0} // provided by ReportingWidgetContainer
                           width={0} />
        </ReportingWidgetContainer>
      );
    } else {
      formattedWidget = <Alert bsStyle="warning">Could not find widget type <em>{widget.type}</em>. Please ensure the plugin is loaded.</Alert>;
    }

    return (
      <ExpandableListItem key={widget.id}
                          header={widget.description}
                          name={dashboard.id}
                          value={widget.id}
                          checked={this._isWidgetInReport(report, widget.id)}
                          onChange={this._updateWidgetSelection}>
        {formattedWidget}
      </ExpandableListItem>
    );
  };

  _formatDashboardInput = (report, dashboard, numberDashboards) => {
    const numberWidgets = dashboard.widgets.length;
    const widgetCount = (
      <span>
        contains {numberWidgets} <Pluralize value={numberWidgets} singular="widget" plural="widgets" />
      </span>
    );

    const sortedWidgets = dashboard.widgets
      .sort((w1, w2) => naturalSortIgnoreCase(w1.description, w2.description));
    const numberDashboardWidgetsInReport = this._numberDashboardWidgetsInReport(report, dashboard);
    const isDashboardChecked = numberDashboardWidgetsInReport === dashboard.widgets.length;
    const isDashboardCheckIndetermined = numberDashboardWidgetsInReport > 0 && !isDashboardChecked;

    return (
      <ExpandableListItem key={dashboard.id}
                          header={dashboard.title}
                          subheader={widgetCount}
                          name={dashboard.id}
                          value="dashboard"
                          expanded={numberDashboards <= MAX_DASHBOARDS_TO_EXPAND}
                          checked={isDashboardChecked}
                          indetermined={isDashboardCheckIndetermined}
                          onChange={this._updateWidgetSelection}>
        <ExpandableList>
          {sortedWidgets.map(widget => this._formatWidgetInput(report, dashboard, widget))}
        </ExpandableList>
      </ExpandableListItem>
    );
  };

  render() {
    const { action, formElementId, dashboards, report, reportLogo, onCancel, isLoading } = this.props;
    const { logoError } = this.state;
    if (dashboards.length === 0) {
      return (
        <div>
          <Alert bsStyle="info">
            Reporting is based on dashboards, create the widgets you want to include in the report to get started.
          </Alert>
        </div>
      );
    }

    return (
      <div>
        <h3>Contents</h3>
        <p>
          Write a title and description for the report and select the widgets that will be include in it.
        </p>

        <form id={formElementId} onSubmit={this._saveReport}>
          <Input id="title"
                 name="title"
                 type="text"
                 label="Title"
                 help="Set a title to use in the report's cover page."
                 value={report.title}
                 onChange={this._updateReport}
                 required />
          <Input id="subtitle"
                 name="subtitle"
                 type="text"
                 label={<span>Subtitle <small className="text-muted">(Optional)</small></span>}
                 help="Set a subtitle to use in the report's cover page."
                 value={report.subtitle || ''}
                 onChange={this._updateReport} />
          <FormGroup controlId="logo" validationState={logoError ? 'error' : null}>
            <ControlLabel>Logo <small className="text-muted">(Optional)</small></ControlLabel>
            <div className="clearfix" />
            {reportLogo && (
              <React.Fragment>
                <div className={`${commonStyles.logo} ${style.logo}`}>
                  <img src={reportLogo} alt="report-logo" />
                </div>
                <div className={style.removeLogo}>
                  <Button bsSize="xsmall" bsStyle="primary" onClick={this._removeReportLogo}>
                    Remove logo
                  </Button>
                </div>
              </React.Fragment>
            )}
            <FormControl id="logo"
                         type="file"
                         accept="image/png,image/jpeg"
                         onChange={this._updateReportLogo} />
            <HelpBlock>
              {logoError ? logoError.message : (
                <span>
                  Choose an image to use as a logo in the report&#39;s cover page. The image must be in JPEG
                  or PNG formats and cannot exceed {numeral(MAX_LOGO_SIZE).format('0 b')}.
                </span>
              )}
            </HelpBlock>
          </FormGroup>
          <Input id="description"
                 name="description"
                 type="textarea"
                 label={<span>Description <small className="text-muted">(Optional)</small></span>}
                 help="Add a description to include at the beginning of the report."
                 value={report.description || ''}
                 onChange={this._updateReport}
                 rows={4} />

          <FormGroup>
            <ControlLabel>Widgets</ControlLabel>
            <HelpBlock>
              Select the widgets to include in the report. You can create new widgets and add them to the report later
              on.
            </HelpBlock>
          </FormGroup>

          <ExpandableList>
            {dashboards.map(dashboard => this._formatDashboardInput(report, dashboard, dashboards.length))}
          </ExpandableList>

          <ReportContentsToolbar action={action} onCancel={onCancel} formElementId={formElementId} isLoading={isLoading} />
        </form>
      </div>
    );
  }
}

export default ReportContentsSelection;
