import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import Reflux from 'reflux';
import { Col, Row } from 'react-bootstrap';

import { RenderCompletionObserver } from 'components/visualizations';

import ReportingWidget, { FallbackReportingWidget } from '../common/ReportingWidget';
import ErrorBoundary from '../common/ErrorBoundary';

import ReportsActions from '../ReportsActions';
import ReportsStore from '../ReportsStore';

import style from './ReportRenderPage.css';

const WIDGET_ASPECT_RATIO = 3 / 4;
const WIDGET_WIDTH = 600;

const ReportRenderPage = createReactClass({
  displayName: 'ReportRenderPage',

  propTypes: {
    params: PropTypes.object.isRequired,
  },

  mixins: [Reflux.connect(ReportsStore)],

  getInitialState() {
    return {
      renderedWidgets: [],
    };
  },

  componentDidMount() {
    this._loadReport(this.props.params.reportId);
  },

  componentWillReceiveProps(nextProps) {
    if (this.props.params.reportId !== nextProps.params.reportId) {
      this._loadReport(nextProps.params.reportId);
    }
  },

  _loadReport(id) {
    ReportsActions.get(id);
    ReportsActions.getReportLogo(id);
  },

  _handleRenderComplete(widgetId) {
    return () => {
      this.setState({ renderedWidgets: this.state.renderedWidgets.concat([widgetId]) });
    };
  },

  _renderWidgets(widgets, positions) {
    return widgets
      .sort((widgetA, widgetB) => {
        if (positions.length === 0) {
          return 0;
        }
        const positionA = positions.find(p => p.dashboard_widget_id === widgetA.dashboard_widget_id);
        const positionB = positions.find(p => p.dashboard_widget_id === widgetB.dashboard_widget_id);
        return (positionA && positionB ? positionA.row - positionB.row : 0);
      })
      .map((widget) => {
        // Width is going to be limited by the paper size, but we try our best to adjust the visualization height.
        const effectiveHeight = WIDGET_WIDTH * WIDGET_ASPECT_RATIO;
        const handleRenderComplete = this._handleRenderComplete(widget.dashboard_widget_id);
        return (
          <div key={widget.dashboard_widget_id} className={style.visualization}>
            <RenderCompletionObserver onRenderComplete={handleRenderComplete}>
              <ErrorBoundary FallbackComponent={FallbackReportingWidget}
                             onDidCatch={handleRenderComplete}
                             widget={widget}>
                <ReportingWidget dashboardId={widget.dashboard_id}
                                 widget={widget}
                                 width={WIDGET_WIDTH}
                                 height={effectiveHeight}
                                 interactive={false} />
              </ErrorBoundary>
            </RenderCompletionObserver>
          </div>
        );
      });
  },

  _isRenderComplete(widgets, renderedWidgets) {
    return widgets.length === renderedWidgets.length;
  },

  render() {
    const { report, renderedWidgets, reportLogo } = this.state;
    const isLoading = !report;
    if (isLoading) {
      return null;
    }

    return (
      <Row>
        <Col md={12}>
          <div className={style.reportPage}>
            <div className={style.coverPage}>
              <h1>{report.title}</h1>
              <h2>{report.subtitle}</h2>
              {reportLogo && <div className={style.logo}><img src={reportLogo} alt="report-logo" /></div>}
            </div>
            <div className={style.pageBreak} />
            <p className={style.description}>{report.description}</p>
            {this._renderWidgets(report.widgets, report.positions)}
            {this._isRenderComplete(report.widgets, renderedWidgets) && <div id="render-complete" style={{ display: 'none' }} /> }
          </div>
        </Col>
      </Row>
    );
  },
});

export default ReportRenderPage;
