import PropTypes from 'prop-types';
import React from 'react';
import createReactClass from 'create-react-class';
import { Button, Checkbox, Col, FormControl, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import naturalSort from 'javascript-natural-sort';

import { Input } from 'components/bootstrap';
import StoreProvider from 'injection/StoreProvider';

const StreamsStore = StoreProvider.getStore('Streams');

const ArchiveConfigStreamSelectionForm = createReactClass({
  displayName: 'ArchiveConfigStreamSelectionForm',

  propTypes: {
    excludedStreams: PropTypes.array.isRequired,
    updateExcludedStreams: PropTypes.func.isRequired,
  },

  getInitialState() {
    return {
      streams: undefined,
      filter: undefined,
    };
  },

  componentDidMount() {
    this.style.use();

    StreamsStore.listStreams().then((streams) => {
      const sortedStreams = streams.sort((a, b) => naturalSort(a.title, b.title));

      this.setState({ streams: sortedStreams });
    });
  },

  componentWillUnmount() {
    this.style.unuse();
  },

  onUpdateCheckbox(streamId) {
    return (e) => {
      // Clone the excludedStreams array.
      let newExcludes = this.props.excludedStreams.slice(0);

      if (e.target.checked) {
        if (newExcludes.indexOf(streamId) !== -1) {
          newExcludes = newExcludes.filter(id => id !== streamId);
        }
      } else if (newExcludes.indexOf(streamId) === -1) {
        newExcludes.push(streamId);
      }

      this.props.updateExcludedStreams(newExcludes);
    };
  },

  onSelectAll(e) {
    let newExcludes = [];

    if (!e.target.checked) {
      newExcludes = this.state.streams.map(stream => stream.id);
    }

    this.props.updateExcludedStreams(newExcludes);
  },

  onFilterChange(e) {
    this.setState({ filter: e.target.value });
  },

  onFilterReset() {
    this.setState({ filter: undefined });
  },

  style: require('!style/useable!css!archive/style.css'),

  isStreamIncluded(stream) {
    return this.props.excludedStreams.indexOf(stream.id) === -1;
  },

  filterListItem() {
    return (
      <Row>
        <Col xs={9} md={6}>
          <FormControl type="text" onChange={this.onFilterChange} placeholder="Filter streams" value={this.state.filter} />
        </Col>
        <Col xs={3} md={2} className="reset-button-container">
          <Button onClick={this.onFilterReset}>Reset</Button>
        </Col>
      </Row>
    );
  },

  headerItem() {
    return (
      <ListGroupItem key="stream-select-all" className="list-group-header stream-selector scrollable-list-item scrollable-list-header">
        <Checkbox onChange={this.onSelectAll}>Select all available streams</Checkbox>
      </ListGroupItem>
    );
  },

  streamItems() {
    const items = this.state.streams.filter((stream) => {
      const text = `${stream.title} ${stream.description}`;
      return !(this.state.filter && text.toLowerCase().indexOf(this.state.filter.toLowerCase()) === -1);
    }).map((stream) => {
      return (
        <ListGroupItem key={`stream-${stream.id}`} className="scrollable-list-item">
          <Checkbox checked={this.isStreamIncluded(stream)} onChange={this.onUpdateCheckbox(stream.id)}>
            <span><strong>{stream.title}</strong> - {stream.description}</span>
          </Checkbox>
        </ListGroupItem>
      );
    });

    if (items.length > 0) {
      return items;
    }

    return (
      <ListGroupItem className="scrollable-list-item">No streams match the filter.</ListGroupItem>
    );
  },

  render() {
    if (!this.state.streams) {
      return (<p className="input-horizontal-form-wrapper">Loading streams...</p>);
    }

    return (
      <div>
        {this.filterListItem()}
        <div className="scrollable-list-container">
          <ListGroup className="scrollable-list">
            {this.headerItem()}
            {this.streamItems()}
          </ListGroup>
        </div>
      </div>
    );
  },
});

export default ArchiveConfigStreamSelectionForm;
