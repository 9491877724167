import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'react-bootstrap';

import HelpPanel from 'components/event-definitions/common/HelpPanel';

import commonStyles from './commonStyles.css';

class CorrelationPreview extends React.Component {
  static propTypes = {
    rules: PropTypes.array.isRequired,
    eventDefinitions: PropTypes.array.isRequired,
  };

  renderRule = (rule) => {
    const { eventDefinitions } = this.props;
    const eventDefinition = eventDefinitions.find(d => d.id === rule.event_creator_equals) || {};
    const title = (rule.event_creator_equals ? eventDefinition.title : 'No Event assigned yet');

    return (
      <li key={rule.id}>{title || 'Event Definition not available'}</li>
    );
  };

  render() {
    const { rules } = this.props;

    return (
      <React.Fragment>
        <HelpPanel collapsible
                   title="How many Events will Event Correlation create?">
          <p>Event Correlation will generate one Event when the series of Events occurs.</p>
        </HelpPanel>

        <Panel className={commonStyles.preview} header={<h3>Correlation Preview</h3>}>
          <p>Preview of execution order of the Event Correlation Rule.</p>
          {rules.length === 0 ? (
            <p>No Event Correlation Rules configured yet.</p>
          ) : (
            <ol className={commonStyles.previewList}>{rules.map(this.renderRule)}</ol>
          )}
        </Panel>
      </React.Fragment>
    );
  }
}

export default CorrelationPreview;
